.social-btn {
  padding: 0 20px;
  height: 64px;
  background-color: transparent;
  border: 1px solid var(--text);
  border-radius: 12px;
  color: var(--text);
  transition: all var(--ptf-transition-duration) var(--ptf-transition-easing);
  svg {
    margin-right: 10px;
  }
  &:hover {
    border-color: var(--ptf-accent-1);
  }
}
.Toastify__toast {
  font-family: var(--ptf-primary-font);
  color: var(--ptf-color-black);
  border-radius: 12px;
}
.login-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-social {
    display: flex;
    column-gap: 16px;
  }
  @media (max-width: 768px) {
    display: block;
    .footer-social {
      display: block;
      margin-top: 16px;
      button {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}
