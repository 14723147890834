.introduction {
  color: var(--text);
  line-height: 2.2em;

  iframe {
    display: block !important;
  }

  .title {
    color: var(--text);
    font-size: 48px;
    margin-bottom: 25px;
  }

  .introduction-img-container {
    margin-top: 12px;
    display: flex;
    gap: 12px;

    .img-responsive {
      width: 50%;
      border-radius: 8px;
    }

    .img-responsive-full {
      width: 100%;
      border-radius: 8px;
      margin: 5px auto;
    }

    @media (max-width: 900px) {
      display: block;

      .img-responsive {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  .introduction-title {
    font-size: 32px;
    color: var(--text);
    margin: 25px 0 15px 0;
  }

  .introduction-subTitle {
    font-size: 16px;

    b {
      color: #fa4529;
    }
  }

  .line-break {
    margin: 20px 0 0 0;
  }

  .introduction-contentTitle {
    font-size: 24px;
    color: var(--text);
    margin: 30px 0 20px 0;
  }

  .inner-text {
    padding-bottom: 20px;
  }

  .introduction-link {
    color: #00a3fa;

    &:hover {
      text-decoration: underline;
    }
  }

  .step-one {
    line-height: 2.2em;
  }

  .introduction-step-1 {
    color: var(--text);
    font-weight: 700;
    margin-right: 8px;
  }

  .introduction-step {
    background: var(--text);
    border-radius: 6px;
    color: var(--black-color);
    padding: 1px 3px;
    margin-right: 8px;
    margin-left: 8px;
    word-wrap: break-word;

    @media (max-width: 762px) {
      padding: 0;
    }
  }

  .introduction-step-2 {
    word-wrap: break-word;
    background: var(--text);
    border-radius: 6px;
    color: var(--black-color);

    margin-right: 8px;

    @media (max-width: 762px) {
      padding: 0;
    }
  }

  .strong {
    font-weight: 700;
    color: #00a3fa;
  }

  .iframe {
    display: block !important;
    margin: 0 auto;

    @media (max-width: 762px) {
      width: 100%;
    }
  }

  .introduction-code {
    background: var(--theme-code);
    margin-top: 20px;
    border-radius: 8px;

    .text-black {
      color: #bcc0c2 !important;
    }

    &:hover {
      .copy {
        display: block;
      }
    }

    hr {
      margin: 0;
    }

    p {
      padding: 12px 16px;
      color: #bcc0c2;
      font-size: 16px;
    }

    div {
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;

      span {
        color: #bcc0c2;
        padding: 2px 10px;
      }

      .copy {
        cursor: pointer;
        padding: 2px 10px;
        background: var(--black-color);
        color: var(--text);
        border-radius: 8px;
        display: none;
        height: 100%;
      }
    }
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background: transparent;
    border-bottom: 2px solid #084298;
    color: #0d6efd;
  }

  .nav-link {
    padding: 4px 10px;
    margin-bottom: 20px;
    border: none;

    &:hover {
      border: none;
    }
  }

  .next-page-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }

  .previous-page-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 50px;
  }

  .center-page-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .previous,
  .next {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 8px;
    min-height: 80px;
    width: 40%;
    border: 1px solid var(--text);
    color: var(--text);

    &:hover {
      border-color: #fa4529;
    }

    .link {
      color: #fa4529;
      font-size: 20px;
      letter-spacing: 0.8px;

      @media (max-width: 762px) {
        font-size: 16px;
      }
    }

    p {
      margin: 0;
      width: 100%;
    }
  }
}

.hover-img {
  &:hover {
    transform: scale(1.1);
  }
}

.iframe-wrapper {
  height: 400px;

  iframe {
    display: block !important;
    width: 100%;
    height: 100%;
  }
}
