/* PARTIAL: FILLED LINK
-------------------------------------------- */
.ptf-animated-block {
  .ptf-filled-link {
    display: inline-flex;

    color: var(--text) !important;

    background-image: linear-gradient(
      to right,
      var(--ptf-accent-1),
      var(--ptf-accent-1) 50%,
      currentColor 50%
    );
    background-position: 100% 0;
    -webkit-background-clip: text;
    //background-clip: text;
    background-size: 200% 200%;

    transition: background-position calc(var(--ptf-transition-duration) * 2)
      var(--ptf-transition-easing);

    -webkit-text-fill-color: transparent;

    &:hover {
      background-image: linear-gradient(
        to right,
        var(--ptf-accent-1),
        var(--ptf-accent-1) 50%,
        currentColor 50%
      );
      background-position: 0 0;
    }
  }

  .has-white-color svg {
    color: var(--text);
    transition: color var(--ptf-transition-duration)
      var(--ptf-transition-easing);
  }

  &:hover {
    .has-white-color svg {
      color: #2dac16;
    }
  }
}

.ptf-animated-block-right {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
