:root {
  --body: #ffffff;
  --card: #ffffff;
  --image: url("../../assets/img/night-mode.png");
  --image-step-1: url("../../assets/img/root/about-me-bubble-2.png");
  --image-step-4: url("../../assets/img/root/home-freelancer-bubble-2.png");
  --black-color: #ffffff;
  --opacity: none;
  --text: #000000;
  --text-color: #000000;
  --box-shadow: rgba(44, 44, 44, 0.4);
  --box-shadow-2: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px;
  --theme-code: #3b3f41;
  --text-grey: #666666;
  --black-text: #ffffff;
  --text-light: hsla(120, 77%, 38%, 1);
  --text-danger: hsla(120, 77%, 38%, 1);
  --text-dark: #000000;
  --border-color: rgba(217, 217, 217, 0.87);
}

[data-theme="dark"] {
  --body: #121212;
  --card: #333334;
  --image: url("../../assets/img/sun.png");
  --image-step-1: url("../../assets/img/root/home-dark-bubble-1.png");
  --image-step-4: url("../../assets/img/root/home-dark-bubble-2.png");
  --black-color: #202124;
  --opacity: 0.45;
  --text: #ffffff;
  --text-color: #dadada;
  --box-shadow: rgba(237, 237, 237, 0.4);
  --box-shadow-2: transparent;
  --theme-code: #3b3f41;
  --black-text: #000000;
  --text-light: #ffffff;
  --text-grey: #e3e3e3;
  --text-danger: #ffffff;
  --text-dark: #999999;
  --border-color: #121212;
}
