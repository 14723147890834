.text-Logo {
  color: var(--text);
}

.has-3-color {
  color: var(--text-dark) !important;
}

.has-2-color {
  color: var(--text-dark) !important;
}
.has-color {
  &:hover {
    color: var(--text-light) !important;
  }
}

.ptf-footer--style-5 {
  background: var(--body) !important;
}

.has-gray-color {
  color: var(--text-grey) !important;
}
