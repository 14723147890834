@keyframes ptf_animate_icon_right {
  49% {
    transform: translateX(100%);
  }

  50% {
    opacity: 0;

    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes ptf_animate_icon_left {
  49% {
    opacity: 0;

    transform: translateX(-50%);
  }

  50% {
    opacity: 0;

    transform: translateX(50%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes ptf_fade_in_down {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes ptf_fade_out_up {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes ptf_blink_in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ptf_bounce_preloader {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes ptf_spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ptf_bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}
