.user-profile {
  .container {
    height: calc(132px-100vh);
    .row {
      .card {
        height: 100%;
        border: none;
        .card-body {
          select {
            border: none;
            width: 75% !important;
            overflow: hidden;
            cursor: pointer;
          }
        }
      }
    }
  }
}
