.vakansiya {
  .card {
    background: transparent;
    border: 1px solid var(--text) !important;
    cursor: pointer;
    margin: 15px auto;
    color: var(--text) !important;
    &.active {
      border: 1px solid #2dac16 !important;
    }
  }

  .card-header {
    background: transparent;
    border: none !important;
    .header-status {
      font-size: 18px;
      animation: animated 1s alternate infinite;
      margin-left: 5px;
    }
    .card-header-place {
      color: var(--text) !important;
    }
  }

  .card-status {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: calc(100% - 20px);
    padding: 0 16px;
    gap: 10px;
  }

  .card-status .status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: #f3f2f1;
    color: #595959 !important;
    padding: 0 10px;
    border-radius: 5px;
  }

  .card-body {
    p {
      color: #969696;
    }
  }
}

.tab-content {
  background: transparent;
  margin: 16px 0;
  border-radius: 4px;
  border: 1px solid var(--text);
  color: var(--text) !important;
  height: calc(100vh - 160px);
  overflow: hidden !important;

  .tab-pane {
    overflow: hidden !important;

    .card-header {
      width: 100%;
      height: 180px;
      padding: 10px 16px;
      box-shadow: 1px 0 7px 1px var(--box-shadow);
    }

    .card-body {
      overflow-y: scroll;
      margin-top: 15px;
      padding: 0 16px 200px 16px;
      height: calc(100vh - 180px);
      color: var(--text-color) !important;
    }
  }

  .apple-button {
    border-radius: 6px;
    padding: 4px 25px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    margin-top: 5px;
  }

  .job-title {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 3px;
  }

  .price {
    background: #f3f2f1;
    color: #595959 !important;
    padding: 0 10px;
    border-radius: 5px;
  }

  .card-status {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: calc(100% - 20px);
    gap: 10px;
  }

  .card-status .status {
    background: #f3f2f1;
    color: #595959 !important;
    padding: 0 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

@keyframes animated {
  from {
    color: var(--text);
  }
  to {
    color:  #2dac16;
  }
}
