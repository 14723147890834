.docMenu {
  .sidebar-menu_wrapper .pro-menu-item.pro-sub-menu {
    width: 250px;
    @media (max-width: 1200px) {
      width: 220px;
    }
  }

  .pro-sidebar > .pro-sidebar-inner {
    background: transparent !important;
    width: 100% !important;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 18px !important;
    color: var(--text);

    .active {
      color: #2dac16 !important;
    }
  }

  .pro-sidebar .pro-menu a {
    &:hover {
      color: #2dac16 !important;
      padding-left: 0;
    }
  }

  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    border-color: var(--text);
  }

  .pro-item-content {
    a {
      color: var(--text) !important;
      font-size: 18px !important;

      .active {
        color:  #2dac16 !important;
      }
    }
  }
}

.docContainer {
  min-height: 100vh;
  background: var(--body);

  .docMenuContainer {
    max-width: 100%;
    width: max-content;
    display: block !important;
    @media (max-width: 992px) {
      display: none !important;
    }

    .docMenu {
      .pro-sidebar {
        z-index: 1 !important;
      }
    }
  }

  .docContent {
    max-width: 70%;
    width: 100%;
    word-wrap: break-word !important;
    @media (max-width: 1200px) {
      max-width: 68%;
    }
    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
}

.mobile-document-menu {
  display: none !important;
  @media (max-width: 992px) {
    display: block !important;
  }
}

.nav-link-active {
  &.active {
    color: #2dac16 !important;
  }
}

.ptf-footer-doc {
  margin-top: 100px;
  padding-bottom: 50px;
}
