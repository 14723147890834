.features {
  background: var(--card);
  border-radius: 0;
  white-space: normal;
  box-shadow: var(--box-shadow-2);
  border: 0;
  height: auto;
  .card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    gap: 5px;

    .features-icon {
      font-size: 40px;
    }

    .features-tag {
      text-transform: lowercase;
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 30px;
      border-radius: 20px;
      font-size: 14px;
    }
  }
  .card-body {
    text-align: left;
    padding: 0 16px 16px 16px;
    .card-title {
      font-size: 15px;
      text-transform: uppercase;
    }
    .card-text {
      font-size: 14px;
    }
  }
}
